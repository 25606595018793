/* Regular */

@font-face {
    font-family: "Sangbleu Republic";
    src: url("../fonts/sangbleu_republic/SangBleuRepublic-Regular-WebS.woff2") format("woff2"),
        url("../fonts/sangbleu_republic/SangBleuRepublic-Regular-WebS.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sangbleu Republic";
    src: url("../fonts/sangbleu_republic/SangBleuRepublic-RegularItalic-WebS.woff2") format("woff2"),
        url("../fonts/sangbleu_republic/SangBleuRepublic-RegularItalic-WebS.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

/* Medium */

@font-face {
    font-family: "Sangbleu Republic";
    src: url("../fonts/sangbleu_republic/SangBleuRepublic-Medium-WebS.woff2") format("woff2"),
        url("../fonts/sangbleu_republic/SangBleuRepublic-Medium-WebS.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sangbleu Republic";
    src: url("../fonts/sangbleu_republic/SangBleuRepublic-MediumItalic-WebS.woff2") format("woff2"),
        url("../fonts/sangbleu_republic/SangBleuRepublic-MediumItalic-WebS.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/* Bold */

@font-face {
    font-family: "Sangbleu Republic";
    src: url("../fonts/sangbleu_republic/SangBleuRepublic-Bold-WebS.woff2") format("woff2"),
        url("../fonts/sangbleu_republic/SangBleuRepublic-Bold-WebS.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sangbleu Republic";
    src: url("../fonts/sangbleu_republic/SangBleuRepublic-BoldItalic-WebS.woff2") format("woff2"),
        url("../fonts/sangbleu_republic/SangBleuRepublic-BoldItalic-WebS.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
